/* @font-face {
    font-family: "CooperHewitt";
    src:local("CooperHewitt"), url("./fonts/CooperHewitt-Bold.otf") format("opentype");
    font-weight: bold;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local("CooperHewitt"), url("./fonts/CooperHewitt-BoldItalic.otf") format("opentype");
    font-weight: bold;
    font-style: italic;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-Book.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-BookItalic.otf") format('opentype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-Heavy.otf") format('opentype');
    font-weight: 600;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-HeavyItalic.otf") format('opentype');
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-Thin.otf") format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-Light.otf") format('opentype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-LightItalic.otf") format('opentype');
    font-weight: normal;
    font-style: italic;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-Medium.otf") format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-MediumItalic.otf") format('opentype');
    font-style: italic;
    font-weight: normal;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-Semibold.otf") format('opentype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-SemiboldItalic.otf") format('opentype');
    font-style: italic;
    font-weight: 700;
  }
  @font-face {
    font-family: "CooperHewitt";
    src:local('CooperHewitt'), url("./fonts/CooperHewitt-ThinItalic.otf") format('opentype');
    font-style: italic;
    font-family: 300;
  } */

@import "~fontsource-cooper-hewitt/index.css";
@import "~fontsource-cooper-hewitt/all-700.css";
@import "~fontsource-montserrat/index.css";

body{
    font-family: 'Cooper Hewitt';
}