

.intro{
  height: 110vh;
  background-position: center;
}
.intro-mobile{
  height: 100vh;
  background-position: center;
}
.intro2{
  height: 98vh;
  background-position: center;
}
.intro2-mobile{
  height: 100vh;
  background-position: center;
}
.ban2-box{
  margin-top: 100px;
}
.ban1-box,
.ban2-box{
  padding-top: 45px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  background-color: grey;
}
.ban2-box h2,
.ban1-box h2{
  font-family: 'Montserrat';
  font-weight: 600;
  color: white;
  font-size: 2.4em;
}
.ban2-box p,
.ban1-box p{
  font-family: 'Montserrat';
  font-weight: 400;
  font-style: normal;
  color: white;
  font-size: 1.8em;
  line-height: 1.4em;
}
.intro-box{
  margin-top: 90px;
}
.evids{
  padding-left: 40px;
}
.evideo{
  padding-right: 40px;
}
.video{
  margin-bottom: 100px;
}
.videos-responsive {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  height: 0;
}
.videos-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.vids-responsive {
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
  height: 0;
}
.vids-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: box-shadow .15s ease-in-out;
}
.navbar-toggler-icon {
    background-color: #ff0000f7;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: rgb(255 9 9 / 90%);
}
.logo-web{
  height: 50px;
  width: auto;
  /* width: 30%; */
}
.logo-footer{
  width: 25%;
  /* height: 75px; */
}
.navbar {
  --bs-navbar-padding-y: 0;
}
.navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-left: -25px;
}
.nav-item a{
  color: #fff;
  letter-spacing: 0.06em;
  --bs-nav-link-hover-color: red;
  font-size: 0.8em;
}
.bg-dark{
  text-align: center;
}
.nav-item{
  padding-left: 10px;
}
.esm-image{
  width: 100%;
  height: 610px;
}
.pro-scoot{
  margin-top: 60px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.pro-head,
.pro-head-mobile{
  padding-top: 60px;
  font-weight: 600;
  font-family: "Cooper Hewitt";
  
}
.pro-head span,
.pro-head-mobile span{
  color: red;
}
.pro-box{
  padding-top: 55px;
  padding-bottom: 40px;
  padding-left: 15px;
  border-radius: 20px;
  background-color: grey;
}
.pro-box h2{
  color: white;
  letter-spacing: 0.02em;
  font-family: "Cooper Hewitt";
  font-weight: 600;
  /* font-style:normal; */
}
.pro-box h3{
  font-weight: 400;
  color: white;
  font-family: "Cooper Hewitt";
  letter-spacing: 0.02em;
}
.pro-box li{
  letter-spacing: 0.02em;
  font-size: 1.6em;
  color: white;
  font-family: "Cooper Hewitt";
  font-weight: 500;
}
.ssvw-scoot{
  margin-top: 20px;
  margin-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px;      
}
.ssvw-box{
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  background-color: grey;
}
.btn-primary{
  margin-top: 20px;
  text-align: center;
  width: 30%;
  background-color: red;
  font-family: 'Cooper Hewitt';
}
.reachout-form-box{
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 50px;
  border-radius: 20px;
  background-color: #5a5858;
}
.ssvw-box h2{
  color: white;
  letter-spacing: 0.02em;
  font-size: 1.8em;
  font-weight: 400;
  font-style: normal;
  line-height: 1.4em;
  font-family: 'Cooper Hewitt';
}
.ssvw-box-head{
  padding-bottom: 15px;
}
.ssvw-box-head h1{
  color: black;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
}
.ssvw-box-head h1 span,
.ssvw-box-head h3 span{
  color: red;
}
.ssvw-box-head h3{
  color: black;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
  margin-top: -5px;
}
.ssvw-image,
.ssvw-image-mobile{
  margin-top: -100px;
}
.ssvw-image img,
.ssvw-image-mobile img{
  width: 100%;
}
.evorange{
  margin-bottom: 100px;
  margin-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
}
.evo-range-specs{
  margin-top: 50px;
}
.evo-range-specs h1{
  font-weight: 600;
  color: black;
  font-size: 1.8em;
  font-family: 'Cooper Hewitt';
}
.evo-range-specs h1 span,
.evo-range-head h1 span{
  color: red;
}
.evo-range-first-image img,
.evo-range-second-image img,
.evo-range-third-image img{
  width:600px;
}
.evo-range-first-image{
  margin-top: -100px;
  margin-left: -40px;
}
.evo-range-second-image{
  margin-left: -80px;
  margin-top: -180px;
}
.evo-range-third-image{
  margin-left: -140px;
  margin-top: -180px;
}
.evo-range-fourth-image img,
.evo-range-fifth-image img{
  width:100%;
  /* height: 300px; */
  border-radius: 20px;
}
.evo-range-head h1{
  color: black;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
}
.evo-range-head h3{
  color: black;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
}
.evo-range-head h1{
  font-size: 2.8em;
}
.evo-range-head h3{
  font-size: 2em;
}
.evo-range-box{
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  background-color: grey;
}
.evo-range-box h2{
  color: white;
  letter-spacing: 0.02em;
  font-size: 1.8em;
  font-weight:400;
  line-height: 1.4em;
  font-family: 'Cooper Hewitt';
  font-style: normal;
}
.evo-range-sixth-image img{
  width: 100%;
}
.evo-range-sixth-image{
  margin-top: -480px;
  margin-left: 40px;
}
/* .evo-range-smart-image img{
  height: 600px;
} */
.evo-smart-image img{
  height: auto;
  width: 100%;
}
.evo-smart-app-image img{
  width: 100%;
  height: auto;
}
.evo-smart-app-first-image img{
  width: 100%;
  height: auto;
}
.evosmart{
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
/* .evo-smart-image{
  margin-left: 40px;
  margin-top: 60px;
}
.evo-smart-app-image{
  margin-left: 60px;
} */
.evo-smart-head h1,
.evo-smart-head-mobile h1{
  color: black;
  font-weight: 600;
  font-family:'Cooper Hewitt';
}
.evo-smart-head h1 span,
.evo-smart-head-mobile span{
  color: red;
}
.evo-smart-head h3,
.evo-smart-head-mobile h3{
  padding-top: 15px;
  padding-bottom: 20px;
  line-height: 1.4em;
  letter-spacing: 0.02em;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
}
.evoswap{
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.evoswap-head h1{
  font-weight: 600;
  color: black;
  font-family:'Cooper Hewitt';
}
.evoswap-head h1 span,
.evoswap-head h3 span{
  color: red;
}
.evoswap-head h3{
  font-weight: 600;
  font-style: normal;
  font-family: 'Cooper Hewitt';
  letter-spacing: 0.02em;
  line-height: 1.2em;
  font-size: 2em;
}
.evoswap-image img{
  width: 100%;
}
.evoswap-first-image img{
  border-radius: 20px;
  width:100%;
  /* height: 300px; */
}
.evoswap-second-image img{
  border-radius: 20px;
  /* height:300px; */
  width:100%;
}
.evoswap-box{
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 15px;
  border-radius: 20px;
  background-color: grey;
}
.evoswap-box h2{
  color: white;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: 'Cooper Hewitt';
}
.evoswap-box h2 span{
  color: red;
}
.evoswap-box li{
  letter-spacing: 0.02em;
  font-size: 1.6em;
  color: white;
  font-family: 'Cooper Hewitt';
}
.evoswap-box{
  margin-top: 20px;
}
.evoswap-sub-head h3 span{
  color: red;
}
.evoswap-sub-head h3{
  font-weight: 400;
  color: black;
  font-size: 1.6em;
  padding-top: 40px;
}
.evocharge{
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.evocharge-head h1,
.evocharge-head-mobile h1{
  font-weight: 600;
  color: black;
  margin-left: 60px;
  font-family: 'Cooper Hewitt';
}
.evocharge-head h1 span,
.evocharge-head h3 span,
.evocharge-head-mobile h3 span,
.evocharge-head-mobile h1 span{
  color: red;
}
.evocharge-head h3,
.evocharge-head-mobile h3{
  margin-left: 60px;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.2em;
  font-size: 2em;
  font-family: 'Cooper Hewitt';
}
.evocharge-image img,
.evocharge-image-mobile img{
  margin-left: 10px;
  width: 100%;
}
.evocharge-first-image img{
  border-radius: 20px;
  /* height: 200px; */
  width: 100%;
  height: 350px;
}
.evocharge-second-image img{
  border-radius: 20px;
  width:100%;
  height:350px;
}
.evocharge-box{
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 15px;
  border-radius: 20px;
  background-color: grey;
}
.evocharge-box h2{
  color: white;
  letter-spacing: 0.02em;
  font-weight: 400;
  font-style: normal;
  font-family: 'Cooper Hewitt';
}
.evocharge-box h2 span{
  color: red;
}
.evocharge-box li{
  letter-spacing: 0.02em;
  font-size: 1.6em;
  font-weight: 400;
  font-style: normal;
  color: white;
  font-family: 'Cooper Hewitt';
}
.evocharge-box{
  margin-top: 20px;
}
.evocharge-sub-head h3 span,
.evocharge-sub-head-mobile h3 span{
  color: red;
}
.evocharge-sub-head h3,
.evocharge-sub-head-mobile h3{
  font-weight: 400;
  /* padding-top: 40px; */
  color: black;
  font-size: 1.6em;
  margin-left: 60px;
}
.evodrive{
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.evodrive-head h1{
  font-weight: 600;
  font-family:'Cooper Hewitt';
  color: black;
}
.evodrive-head h1 span,
.evodrive-head h3 span{
  color: red;
}
.evodrive-head h3{
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1.2em;
  font-size: 2em;
  font-family: 'Cooper Hewitt';
}
.evodrive-image{
  padding-top: 90px;
}
.evodrive-image img{
  margin-top: 20px;
  width: 100%;
  height: auto;
}

.evodrive-first-image img{
  border-radius: 20px;
  /* height: 200px; */
  width: 100%;
  height: 440px;
}

.evodrive-second-image img{
  border-radius: 20px;
  height:440px;
  width:100%;
}

.evodrive-box{
  padding-top: 15px;
  padding-bottom: 20px;
  padding-left: 15px;
  border-radius: 20px;
  background-color: grey;
}
.evodrive-box h2{
  color: white;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-family: 'Cooper Hewitt';
}
.evodrive-box h2 span{
  color: red;
}
.evodrive-box li{
  letter-spacing: 0.02em;
  font-size: 1.6em;
  color: white;
  font-family: 'Cooper Hewitt';
}
.evodrive-box{
  margin-top: 20px;
}
.evodrive-sub-head h3 span{
  color: red;
}
.evodrive-sub-head h3{
  font-weight: 400;
  padding-top: 50px;
  /* margin-top: 10px; */
  color: black;
  font-size: 1.6em;
}
.vision{
  margin-top: 20px;
  margin-bottom: 100px;
}
.vision-box{
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  background-color: #5a5858;
  opacity: 0.8;
}
.vision-box h2{
  color: white;
  letter-spacing: 0.02em;
  font-size: 1.8em;
  font-weight: 500;
  line-height: 1.4em;
  font-family: 'Cooper Hewitt';
}
.vision-head h1{
  margin-top: 80px;
  color: red;
  font-weight: bold;
  font-family: 'Cooper Hewitt';
  letter-spacing: 0.03em;
  font-size: 2.8em;
}
.reachout-head h1{
  margin-top: 40px;
  color: red;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
  letter-spacing: 0.04em;
  font-size: 2.8em;
}
.evo-allrounder{
  margin-bottom: 100px;
}
.evo-allrounder-head h1 span{
  color: red;
}
.evo-allrounder-head h1{
  font-weight: 600;
  font-family:'Cooper Hewitt';
}
.evo-allrounder img{
  width: 100%;
}
.evo-allrounder-head{
  padding-left: 20px;
}
.evo-story{
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.evo-story-image{
  margin-left: 20px;
}
.evo-story-image img{
  width: 100%;
  height:auto;
  border-radius: 70px;
  border:black solid 2px;
}
.evo-story-head h1 span,
.evo-story-head h3 span,
.evo-story-head-mobile h1 span,
.evo-story-head-mobile h3 span{
  color: red;
}
.evo-story-head h1,
.evo-story-head-mobile h1{
  padding-top: 140px;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
}
.evo-story-head h3,
.evo-story-head-mobile h3{
  font-weight: 600;
  font-family: 'Cooper Hewitt';
  font-size: 2em;
  padding-bottom: 50px;
}
.evo-story-box{
  border-radius: 20px;
  background-color: grey;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.evo-story-box h2{
  letter-spacing: 0.02em;
  line-height: 1.4em;
  font-weight: 500;
  color: white;
  font-size: 1.8em;
  font-family: 'Cooper Hewitt';
}
.evo-careers{
  margin-top: 20px;
  margin-bottom: 100px;
  padding-left: 30px;
  padding-right: 30px;
}
.evo-careers-image{
  margin-left: 20px;
}
.evo-careers-image img{
  width: 100%;
  height: auto;
  /* height: 800px; */
  /* border-radius: 70px; */
  /* border:black solid 2px; */
}
.evo-careers-head h1 span,
.evo-careers-head-mobile h1 span{
  color: red;
}
.evo-careers-head h1,
.evo-careers-head-mobile h1{
  padding-top: 60px;
  font-weight: 600;
  font-family: 'Cooper Hewitt';
}
.evo-careers-head h3,
.evo-careers-head-mobile h3{
  font-weight: 600;
  font-family: 'Cooper Hewitt';
  font-size: 2em;
  padding-bottom: 50px;
}
.evo-careers-box{
  border-radius: 20px;
  background-color: grey;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}
.evo-careers-box h2{
  letter-spacing: 0.02em;
  line-height: 1.4em;
  font-weight: 500;
  font-family: 'Cooper Hewitt';
  color: white;
  font-size: 1.4em;
}
.reach-out{
  margin-top: 20px;
  /* margin-bottom: 100px; */
}
/* .footer{
  position: relative;
} */
.footer-background{
  height: 100vh;
}
.reach-out{
  height: 100vh;
}
.footer
  /* background-color: black;
  opacity: 0.8;
  position: absolute;
  bottom: 0px;
  width: 100%; */
  {
    /* position: fixed; */
    bottom: 0;
    left: 0;
    width: 100%;
    /* display: flex; */
    justify-content: center;
    color: #fff;
    /* font-size: 1.5rem; */
    /* background: rgba(0, 0, 0, 0.863); */
    background: black;
/* background: linear-gradient(270deg, rgba(21,21,23,1) 0%, rgba(124,124,125,1) 50%); */
    padding-top: 10px;
    padding-bottom: 10px;
}
.mail-evoride{
  display: flex;
  justify-content: flex-start;
}
.mail-evoride a{
  padding-right: 10px;
  color: red;
  font-size: 1.6rem;
}
.mail h4{
  padding-top: 10px;
  font-size:1.2em;
  color: white;
  font-family: 'Cooper Hewitt';
  font-weight: 400;
}
.fl{
  text-align: center;
}
.social{
  text-align: right;
}
.social h4{
  font-size: 1em;
  color: white;
  font-family: 'Cooper Hewitt';
  padding-top: 10px;
}
.social-text{
  display: flex;
  justify-content:flex-end;
}
.social-text a{
  padding-left: 20px;
  color: red;
  font-size: 1.6rem;
}
.social-text h4{
  font-size: 1em;
  color: white;
  font-family: 'Cooper Hewitt';
}
.social-text span{
  color: red;
}
.form-label{
  color: white;
  font-weight: 500;
  font-family: Cooper Hewitt;
  font-size: 1em;
  padding-top: 15px;
}
@media only screen and (min-width:1200px) and (max-width:1350px){
  .esm-image{
    width:100%;
    height:600px;
    margin-top: 40px;
  }  
}
@media only screen and (min-width:1024px) and (max-width:1200px){
  .esm-image{
    width:100%;
    height:540px;
    margin-top: 80px;
    margin-left: -40px;
  }  
}
@media only screen and (max-width:1024px){
  /* .esm-image{
    width:650px;
    height:600px;
    margin-top: 40px;
  }   */
  .proScoot{
    width: 100%;
  }
}

@media only screen and (min-width:950px) and (max-width:1024px){
  .evo-range-fourth-image{

    margin-left: 20px;
  }
  .evo-range-fifth-image{
    margin-right: 20px;
  }
}
@media only screen and (max-width:1024px){
  .evch,
  .evoChargeNext,
  .evdrNext,
  .evsa,
  .mail,
  .fl,
  .social{
    width: 100%;
  }
  .evids {
    margin-top: 40px;
    width: -webkit-fill-available;
    padding-left: 10px;
  }
  .evideo{
    margin-bottom: 40px;
    padding-right: 0px;
  }
  .pro-scoot{
    margin-top: 0px;
  }
  .evo-story{
    margin-bottom: 0px;
  }
  .ban1-box h2{
    font-size: 1.4em;
  }
  .ban2-box h2{
    font-size: 1.4em;
  }
  .ban2-box p{
    font-size: 1em;
  }
  .ban1-box p{
    font-size: 1em;
  }
  .pro-head,
  .pro-head-mobile,
  .evo-smart-head h1,
  .evo-smart-head-mobile h1,
  .evo-smart-head-mobile h3,
  .evo-smart-head h3,
  .evoswap-head h1,
  .evoswap-head h3,
  .evocharge-head h1,
  .evocharge-head h3,
  .evocharge-head-mobile h1,
  .evocharge-head-mobile h3,
  .evodrive-head h1,
  .evodrive-head h3,
  .evo-allrounder-head h1,
  .vision-head h1,
  .evo-story-head h1,
  .evo-story-head h3,
  .evo-careers-head h1,
  .evo-careers-head h3,
  .evo-story-head-mobile h1,
  .evo-story-head-mobile h3,
  .evo-careers-head-mobile h1,
  .evo-careers-head-mobile h3,
  .ssvw-box-head h1,
  .ssvw-box-head h3{
    font-size: 1.3em;
  }
  .evo-range-head h1,
  .evo-range-head h3{
    text-align: left;
    font-size: 1.3em;
  }
  .evo-range-specs h1{
    text-align: left;
  }
  .evo-smart-head-mobile h3{
    text-align: left;
  }
  .evo-allrounder{
    margin-bottom: 100px;
  }
  .evo-story-box h2,
  .evo-careers-box h2,
  .pro-box h2,
  .pro-box h3,
  .ssvw-box h2,
  .evo-range-specs h1,
  .evo-range-box h2,
  .evoswap-box h2,
  .evocharge-box h2,
  .evodrive-box h2,
  .vision-box h2{
    font-size: 1.2em;
  }
  .pro-box li,
  .evoswap-box li,
  .evocharge-box li,
  .evodrive-box li{
    font-size: 1.2em;
  }
  .evo-allrounder-head h1{
    padding-left: 10px;
  }
  .evoswap-sub-head h3,
  .evocharge-sub-head h3,
  .evocharge-sub-head-mobile h3,
  .evodrive-sub-head h3,
  .evo-smart-head h3,
  /* .evo-smart-head-mobile h3, */
  .mail,
  .fl,
  .social{
    text-align: center;
  }
  .evoswap-sub-head h3,
  .evocharge-sub-head h3,
  .evocharge-sub-head-mobile h3,
  .evodrive-sub-head h3,
  .evo-smart-head h3{
    font-size: 1.2em;
  }
  .mail-evoride{
    justify-content: center;
  }
  .social-text{
    justify-content: center;
  }
  .evo-allrounder-head{
    padding-left: 0px;
  }
  .evo-careers-image,
  .evo-story-image{
    margin-left: 0px;
  }
  .evocharge-head h1,
  .evocharge-head h3,
  .evocharge-sub-head h3,
  .evocharge-head-mobile h1,
  .evocharge-head-mobile h3,
  .evocharge-sub-head-mobile h3{
    margin-left: 0px;
  }
  .evs,
  .evswh,
  .evdr{
    width: 100%;
  }
  .evch,
  .evsp{
    width: 100%;
  }
  .bagspace,
  .evsp1,
  .evsp2,
  .evch1,
  .evch2,
  .evdr1,
  .evdr2{
    width: 50%;
  }
  .evsa1,
  .evsa2{
    width: 50%;
  }
  .evo-range-fourth-image img,
  .evo-range-fifth-image img,
  .evoswap-first-image img,
  .evoswap-second-image img,
  .evocharge-first-image img,
  .evocharge-second-image img,
  .evo-smart-app-first-image img,
  .evo-smart-app-image img{
    margin-bottom: 20px;
  }
  .proScootNext,
  .ssvw-scootnext,
  .evrb,
  .evoStoryImage,
  .evoStory,
  .evc,
  .evc1{
    width: 100%;
  }
  
  .evo-smart-app-image{
    margin-bottom: 30px;
  }
  .evo-range-sixth-image {
    margin-top: -110px;
  }
  .evo-range-box{
    margin-top: 40px;
  }
  .ssvw-image{
    margin-top: -40px;
  }
  .pro-head,
  .pro-head-mobile,
  .ssvw-box-head,
  .evsa1,
  .evsa2,
  .evo-range-head
  .evo-smart-head h1,
  .evo-smart-head-mobile h1,
  .evoswap-head,
  .evocharge-head,
  .evocharge-head-mobile,
  .evodrive-head,
  .evo-allrounder-head,
  .evo-story-head,
  .evo-careers-head,
  .evo-story-head-mobile,
  .evo-careers-head-mobile
  {
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: left;
  }
  .evo-story-head h1,
  .evo-story-head-mobile h1{
    padding-top: 20px;
  }
  .evo-story-head h3,
  .evo-story-head-mobile h3{
    padding-bottom: 20px;
  }
  .evo-smart-image{
    text-align: center;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  .EVR,
  .evi{
    width: 100%;
    text-align: center;
  }
  .evo-range-first-image,
  .evo-range-second-image,
  .evo-range-third-image {
    margin-left: 0px;
  }
  .footer-background{
    height: 60vh;
    background-position: right;
  }
  .reach-out{
    /* height: 60vh; */
    background-position: right;
  }
  .form-control{
    width: 100%;
  }
  .reachout-form-box{
    width: 50%;
    padding-right: 20px;
  }
  .btn-primary{
    font-size: 0.8em;
    width: 100%;
  }
  textarea{
    height: 10px;
  }
  .reachout-head h1{
    font-size: 1.6em;
  }
  .evo-range-box{
    display: none;
  }
  .evoswap-first-image img,
  .evoswap-second-image img,
  .evocharge-first-image img,
  .evocharge-second-image img,
  .evo-range-fourth-image img,
  .evo-range-fifth-image img,
  .evodrive-first-image img,
  .evodrive-second-image img{
    height: auto;
    width: 100%;
  }
  .evo-story-image img,
  .evo-careers-image img{
    width: 100%;
  }
  /* .evodrive-first-image img,
  .evodrive-second-image img{
    height: auto;
  } */
  .vision-head{
    padding-top: 80px;
  }
  .evo-story-image,
  .evo-careers-image,
  .evsp1,
  .evsp2,
  .evch1,
  .evch2,
  .evdr1,
  .evdr2{
    text-align: center;
  }
  .evdr1,
  .evdr2{
    margin-top: 30px;
  }
  .EVR2{
    width: 100%;
    text-align: center;
  }
  .evodrive-image{
    padding-top: 20px;
  }
  .evsa2{
    margin-top: 80px;
  }
  .intro-box{
    margin-top: 40px;
    width: 50%;
  }
  .intro{
    height: auto;
    background-position:center;
  }
  .intro2{
    background-position: bottom;
    height: 80vh;
  }
  .intro2-box{
    margin-left: auto;
    width: 50%;
  }
  .range-image-mobile img{
    width: 100%;
    height: auto;
  }
  /* .evoswap-image-mobile img{
    width: 100%;
  } */
  .EVRM{
    width: 100%;
  }
  /* .pro-head,
  .ssvw-image{
    display: none;
  } */
}
@media only screen and (max-width:570px){
  
  .evo-range-specs,
  .evo-range-first-image,
  .evo-range-second-image,
  .evo-range-third-image,
  .bagspace{
    text-align: center;
  }
  
}
@media only screen and (min-width:1024px) and (max-width:1370px){
  .evo-range-sixth-image{
    margin-top:-400px;
  }
  .evo-range-sixth-image img{
    height: 650px;
  }
}
@media only screen and (min-width:576px) and (max-width:1024px){
  .evr{
    width: 100%;
  }
}
@media only screen and (min-width:790px) and (max-width:1250px){
  .evo-range-third-image{
    margin-left: 0px;
  }
}
@media only screen and (max-width:790px) {
  .evo-range-second-image,
  .evo-range-third-image{
    margin-left: 0px;
  }
  .evi{
    width: 100%;
  }
}

@media only screen and (min-width:600px) and (max-width:780px){
  .esm-image{
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width:600px){
  .esm-image{
    width: 100%;
    height: auto;
  }
  .evo-range-first-image img{
    width: 300px;
  }
  .evo-range-first-image{
    margin-top: 0px;
    margin-left: 0px;
  }
  .evo-range-second-image img{
    width: 300px;
  }
  .evo-range-second-image{
    margin-top: -80px;
  }
  .evo-range-third-image img{
    width: 300px;
  }
  .evo-range-third-image{
    margin-top: -80px;
  }
  .evo-smart-app-first-image img,
  .evo-smart-app-image img{
    width: 100%;
    height: auto;
  }
}
@media only screen and (max-width:650px){
  .evo-range-sixth-image img{
    width: 100%;
  }
  .evo-range-sixth-image{
    margin-top: 0px;
    margin-left: 0px;
  }
}
@media only screen and (max-width:1130px){
  .evo-smart-app-image{
    margin-left: 0px;
  }
}
@media only screen and (min-width:1100px){
  .evoswap-image img,
  .evocharge-image img{
    width: 80%;
  }
  .evodrive-image img{
    width: 80%;
  }
}
@media only screen and (min-width:1900px){
  .evoswap-image img,
  .evocharge-image img{
    width: 700px;
  }
  .evodrive-image img{
    width: 780px;
  }
}
@media only screen and (min-width:1600px){
  .evo-story-image img{
    width: 700px;
    height: 800px;
  }
  .evo-careers-image img{
    width: 700px;
    height: 350px;
  }
  .evo-range-sixth-image img{
    width: 500px;
  }
  .evo-range-sixth-image{
    margin-top: -300px;
    margin-left: 140px;
  }
}
@media only screen and (min-width:600px) and (max-width:865px){
  .evo-range-first-image img,
  .evo-range-second-image img,
  .evo-range-third-image img{
    width: 500px;
  }
  .evo-range-first-image,
  .evo-range-second-image,
  .evo-range-third-image{
    margin-top: -50px;
  }
}
@media only screen and (min-width:2050px){
  .evo-range-fourth-image img,
  .evo-range-fifth-image img{
    height: 500px;
  }
}
@media only screen and (max-width:576px){
  .vision-box{
    width: 70%;
  }
  .ban1-box h2,
  .ban2-box h2{
    font-size: 1.2em;
  }
 .intro2{
  height: auto;
 }
}
@media only screen and (min-width:620px) and (max-width:1024px){
  .intro{
    height: 94vh;
  }
}
@media only screen and (max-width:380px){
  .logo-web{
    /* margin-left: -10px; */
    height: 50px; 
  }
}
@media only screen and (min-width:1022px){
  .pro-head-mobile,
  .ssvw-image-mobile,
  .evo-smart-head-mobile,
  .evocharge-head-mobile,
  .evocharge-image-mobile,
  .evocharge-sub-head-mobile,
  .evo-story-head-mobile,
  .evo-careers-head-mobile,
  .EVRM{
    display: none;
  }
}
@media only screen and (max-width:1022px){
  .pro-head,
  .ssvw-image,
  .evo-smart-head,
  .evocharge-head,
  .evocharge-image,
  .evocharge-sub-head,
  .evo-story-head,
  .evo-careers-head,
  .bagspace,
  .evi,
  .EVR2,
  .evo-range-sixth-image,
  .evo-range-first-image{
    display: none;
  }
  .evo-story-box,
  .evo-careers-box{
    margin-top: 20px;
  }
}
@media only screen and (min-width:576px) and (max-width:1024px){
  .reach-out{
    /* height: 60vh; */
    background-position: right;
  }
  .form-control{
    width: 100%;
  }
  .reachout-form-box{
    width: 100%;
    padding-right: 20px;
  }
  .btn-primary{
    font-size: 0.8em;
    width: 100%;
  }
  textarea{
    height: 10px;
  }
}
@media only screen and (max-width:500px){
  .ban2-box{
    margin-top: 0px;
  }
  .intro-mobile{
    height: 100vh;
    background-position: center;
  }
  .intro2-mobile{
    height: 100vh;
    background-position: center;
  }
  .intro,
  .intro2{
    display: none;
  }
  .ban1-box,
  .ban2-box{
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  background-color: grey;
}
}
@media only screen and (min-width:501px){
  .intro-mobile,
  .intro2-mobile{
    display: none;
  }
}